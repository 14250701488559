.footer-background {
  background: #0d3956;
  height: auto;
  display: flex;
  justify-content: center;
}
.footer-background .footer-max-width {
  max-width: 1440px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}
.footer-max-width .footer-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  padding: 0 2rem;
}
.footer-main-container .email-container {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  justify-content: center;
  align-items: center;
}
.email-container .footer-subs-text {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.5px;
  max-width: 230px;
}
.email-container .input-class {
  width: 519px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #69727d;
  background: #fff;
  outline: none;
  padding: 0 2rem;
  @media(width<650px){
    width: 400px;
  }
  @media(width<500px){
    width: unset;
  }
}
.email-container .footer-subscribe-button{
    color: #0D3956;
text-align: center;
font-family: Montserrat;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 15px; /* 100% */
max-width: 159px;
height: 35px;
flex-shrink: 0;
border-radius: 5px;
background: #FFF;
cursor: pointer;
padding: 8px 20px;
border: none;
outline: none;
}
.footer-main-container .all-footer-link{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1.5rem;
}
.all-footer-link .grid-column-style{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    justify-content: flex-start;
}
.all-footer-link .grid-column-style p{
    color: #FFF;
font-family: Montserrat;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 22.5px;
cursor: pointer;
white-space: nowrap;
}

.all-footer-link .grid-column-style .line-view-with-content{
    display: flex;
    gap: 2.5rem;
    justify-content: center;
}

.line-view-with-content .single-line{
    width: 5px;
height: 328.5px;
flex-shrink: 0;
background: #FFF;
}
.line-view-with-content .flex-column-style{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    justify-content: flex-start;
}
.flex-column-style .social-text{
    color: #FFF;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.5px;
    cursor: pointer;
}
.flex-column-style .information{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: .8rem;
}
.flex-column-style .information p a{
    color: #FFF;
font-family: Montserrat;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 150% */
/* max-width: 166px; */
white-space: nowrap;
}
.flex-column-style .icon-flex{
    display: flex;
    /* align-items: center; */
    justify-content: flex-start;
    gap: 1.2rem;
}

.icon-flex .icon-size{
    font-size: 2rem;
    color: #FFF;
}
.flex-column-style .information .contact-information{
    display: flex;
    gap: .4rem;
}
.footer-max-width .copyright-text p{
    color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 22.5px; /* 150% */
}
.footer-max-width .copyright-text span{
    color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 15px;
font-style: normal;
line-height: 22.5px;
    font-weight: 800;
}

.footer-background a{
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.5px; /* 150% */
}

@media(width<=850px){
    .footer-main-container .all-footer-link{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .line-view-with-content .single-line {
        height: 200px;
    }
}

@media(width<=600px){
    .footer-main-container .all-footer-link{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .line-view-with-content .single-line {
        display: none;
    }
    .all-footer-link .grid-column-style {
        /* justify-content: center; */
        align-items: center;
    }
    .line-view-with-content .flex-column-style {
        align-items: center;
        /* justify-content: center; */
    }
}
@media(width<=600px){
    .footer-main-container .all-footer-link{
        grid-template-columns: 1fr 1fr;
    }
}
@media(width<=425px){
    .footer-main-container .all-footer-link{
        grid-template-columns: 1fr;
    }
}
