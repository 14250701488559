* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Montserrat:ital,wght@0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700&family=Outfit:wght@400;500;600;700;800&family=Roboto:ital,wght@0,500;0,700;0,900;1,500;1,700&display=swap');
html {
  user-select: none;
}
