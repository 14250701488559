.total-heading-background {
  background-image: url("../../../public/assets/Website-Header.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  width: 100%;
  height: auto;
  padding: 60px 0;
}
.total-heading-background .donation-total-content-container {
  max-width: 1250px;
  margin: 0 auto; 
  padding: 0 20px;
  display: flex;
  justify-content: space-between; 
  gap: 1rem;
  flex-direction: column;
  align-items: flex-end;
}
.right-content .donation-text {
  padding: 1.6rem 2.4rem;
  max-width: 600px;
  height: auto;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  justify-content: center;
  align-items: center;
}
.right-content .donation-text h1 {
  color: #444;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.right-content .donation-text p {
  color: #444;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.6px; /* 160% */
}
.donation-pay-content {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  max-width: 600px;
  margin-top: 15px;
}
.donation-pay-content .pay-division-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: rgba(66, 66, 66, 0.5);
}
.donation-pay-content .pay-division-section .wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.pay-division-section p {
  display: flex;
  max-width: 300px;
  height: 39px;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  cursor: pointer;
  color: #222;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.pay-division-section p:active {
  background: #f1f1f1;
}
.donation-form {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 1.5rem;
  background: #f1f1f1;
}

.donation-form .donation-amount {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.donation-form .donation-amount .wrapper-div {
  height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid #ddd;
  background: #fff;
}
.donation-form .donation-amount .wrapper-div:active {
    background-color: #f57324;
}
.donation-form .donation-amount .wrapper-div p {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 22.1px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.donation-form .donation-input-box input {
  display: flex;
  height: 50px;
  padding: 14.5px 30px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 30px;
  background: #fff;
  border: none;
  outline: none;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 18px;
  text-transform: capitalize;
}

.donation-form .donation-input-box input::placeholder {
  color: #757575;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}
.donation-form .amount-button {
  display: flex;
  width: 100%;
  height: 50px;
  padding: 14.5px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 30px;
  background: #f57324;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border: none;
  outline: none;
  cursor: pointer;
}
.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-wrapper .form-input input {
  height: 49.91px;
  padding: 12.45px 10.67px 12.46px 10.67px;
  border-radius: 3.84px;
  border: 3px solid #b0b9c3;
  background: #fff;
  font-size: 21.1px;
  width: 100%;
  outline: none;
}

.form-wrapper .submit-button button {
  height: 60px;
  padding: 17.5px 0px;
  border-radius: 3.84px;
  background: #f57324;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 21.1px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}
.total-support-section {
  display: flex;
  flex-direction: column;
  gap: 6.3rem;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}
.total-support-section .header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  flex-direction: column;
}
.header h1 {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.header .single-line {
  width: 100px;
  height: 5px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #3c7800;
}
.total-support-section .grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2.5rem;
}
.grid-container .single-card {
  max-width: 387.5px;
  flex-shrink: 0;
  height: auto;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px 0px 5px 0px #ccc;
  display: flex;
  flex-direction: column;
  width: 100%;position: relative;
}

.single-card .image-decoration {
  border-radius: 25px 25px 0 0;
  width: 100%;
  max-width: 387.5px;
    max-height: 331.86px;
  /* position: relative; */
}
.single-card .card-details {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 15px;
}
.card-details h1 {
  color: #000;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.card-details .logo-container-with-details {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
}
.logo-container-with-details .logo-with-details {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-start;
}
.logo-container-with-details .logo-with-details p {
  color: #4a4f58;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.logo-with-details .logo-img-size {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid #ddd;
}
.logo-container-with-details .logo-text {
  color: #3a4652;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px; /* 140% */
  min-height: 180px;
}
.donate-details-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
}
.donate-details-container .price-and-details {
  display: flex;
  gap: 0.5rem;
}
.donate-details-container .price-and-details .pricing-text {
  color: #000;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.donate-details-container .price-and-details .goal-text {
  color: #b5b5b5;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 6px;
}
.donate-details-container .supporter-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.donate-details-container .supporter-text p {
  color: #444;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  flex-shrink: 0;
}
.donate-details-container .card-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.donate-details-container .donate-button {
  height: 41px;
  padding: 10px 29.624px 10px 30px;
  border-radius: 30px;
  background: #fd6800;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 117.22px;
  border: none;
  outline: none;
  cursor: pointer;
}
.donate-details-container .achiver-slider {
  display: block;
  background: #c9e1c3;
  height: 10px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.donate-details-container .achiver-slider span {
  display: block;
  background: #3c7800;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
}
.show-more-button button {
  height: 51px;
  padding: 15px 39.636px 15px 40px;
  border-radius: 30px;
  background: #3c7800;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  border: none;
  outline: none;
  cursor: pointer;
  margin-bottom: 3rem;
  margin-top: -3rem;
}
.single-card .relative-content{
    color: #FFF;
font-family: Roboto;
font-size: 17.3px;
font-style: normal;
font-weight: 400;
line-height: normal;
border-radius: 7.68px;
background: #103B5E;
height: 43.03px;
padding: 11.52px 10.992px 11.51px 11.51px;
flex-shrink: 0;
position: absolute;
top: 2%;
right: 5%;
}

@media(width<=1200px){
    .total-support-section .grid-container {
        grid-template-columns: 1fr 1fr;
        padding: 15px;
    }
    .total-heading-background .donation-total-content-container {
        padding: 20px;
    }
    
}
@media(width<=800px){
    .total-heading-background .donation-total-content-container {
        justify-content: center;
        gap: 1rem;
        flex-direction: column;
        align-items: center;
    }
}
@media(width<=600px){
    .total-support-section .grid-container {
        grid-template-columns: 1fr;
        padding: 2rem;
    }
    .right-content .donation-text {
      background: rgba(0,0,0,.3);
    }
    .right-content .donation-text p {
      color: #fff;
      font-weight: 500;
      text-shadow: 2px 2px 3px #222;
    }
    .total-support-section .header h1{
      font-size: 3rem;
    }
    .total-support-section {
      gap: 2.3rem;
    }
}