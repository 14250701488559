.total-success-container {
  padding: 4rem 0;
  /* background: #eceaea; */
}
.total-success-container .header-and-grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4.3rem;
}
.total-success-container .header-and-grid-container .header{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4.3rem;
    margin-bottom: 3rem;
    width: 100%;
}
.header-and-grid-container .header h1 {
  color: #4e84db;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 56px */
  text-transform: capitalize;
}
.header-and-grid-container .header h1 span {
  color: #000;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-transform: capitalize;
}
.header-and-grid-container .success-grid-container {
  display: flex;
  /* grid-template-columns: 1fr 1fr 1fr; */
  /* gap: 3rem; */
  max-width: 1250px;
  width: 100%;
}
.success-grid-container .single-success-grid {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  max-width: 360px;
  padding: 15px;
  margin: 1rem;
  width: 100%;
}
.single-success-grid .success-logo-with-details{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.single-success-grid .success-text{
    color: #000;
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.success-logo-with-details img{
    width: 64px;
    aspect-ratio: 1;
    border-radius: 50%;
}
.success-logo-with-details p{
    color: #4A4F58;
font-family: Roboto;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.single-success-grid .success-description{
    color: #3A4652;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 19.6px; /* 140% */
}
.header .splide__pagination {
  display: none !important;
}

.header .splide__pagination {
  left: 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1;
  bottom: unset !important;
}

.header .splide{
  max-width: 1250px !important;
  padding: 0rem !important;
}
.header .selected-width{
  width: 100%;
}
.header .splide__list {
  gap: .5rem !important;
}

@media(width<=1200px){
  .header .splide{
    max-width: 1100px !important;
  }
}
@media(width<=1050px){
  .header .splide{
    max-width: 1000px !important;
  }
}
@media(width<=600px){
  .header-and-grid-container .header h1 ,.header-and-grid-container .header h1 span {
    font-size: 3rem;
  }
  .total-success-container .header-and-grid-container .header {
    gap: 2.3rem;
  }
}

.splide__arrow--prev,
.splide__arrow--next {
    width: 40px;
    height: 40px;
    background-color: #333;
}

.splide__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.splide__arrow--prev {
  left: 10px;
}

.splide__arrow--next {
  right: 10px;
}

.splide__arrow::before {
  font-family: 'YourIconFont';
  content: '\2190'; 
  font-size: 24px; 
  color: #fff;
}

.splide__arrow--next::before {
  content: '\2192';
}

.splide__arrow:hover::before {
  color: #ff0000;
}