.contributers-background {
  /* max-width: 1400px; */
  height: auto;
  padding: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.contributers-background .contributers-content {
    max-width: 1275px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 45px;
  width: 100%;
}
.contributers-background .contributers-content .contributer-header {
  color: #4e84db;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}
.contributers-background .contributers-content .grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5.5rem;
}
.single-grid {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  /* justify-content: center; */
  align-items: center;
  border-radius: 12px;
  background: var(--Gray-Scale-White, #fff);
  /* box-shadow: 0px 12px 80px 0px rgba(0, 44, 109, 0.05); */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 90%;
  margin: 2rem;
  @media(width=820px){
    height:95%;
  }
}
.single-grid .name-and-donation-amount {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}
.single-grid .name-and-donation-amount h1 {
  color: var(--Gray-Scale-900, #191f33);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: justify;
}
 .single-grid .name-and-donation-amount p {
  color: var(--Gray-Scale-500, #767e94);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}
.single-grid .details-text p {
  color: var(--Gray-Scale-700, #464d61);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0 2.4rem;
  text-align: justify;
}
.single-grid .image-style{
    width: 64px;
    height: 64px;
    border-radius: 50%;
}
.carousel .slide img {
    width: 64px !important;
    aspect-ratio: 1;
    border-radius: 50%;
}
.carousel .control-dots {
    display: none;
}
.carousel-status{
    display: none;
}

.contributers-content .splide__pagination {
  left: 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1;
  bottom: unset !important;
}

.contributers-content .splide{
  max-width: 1250px !important;
  padding: 3rem !important;
}
.contributers-content .selected-width{
  width: 100%;
}
.contributers-content .splide__list {
  gap: .5rem !important;
}
.fPimMH {
width: 100% !important;
}

@media(width<=1200px){
 .contributers-content .splide{
    max-width: 1100px !important;
  }
}
@media(width<=1050px){
 .contributers-content .splide{
    max-width: 1000px !important;
  }
  .single-grid .details-text p {
    padding: 0;
  }
}
@media(width<=768px){
  .contributers-background {
    padding: 0;
  }
}
/* @media screen and (max-width: 767px){
  .cIFHrE, .kRfUeC {
  padding-left: unset !important;
    grid-template-columns: repeat(2,100%) !important;
    grid-column-gap:unset !important;
  }
} */



/* @media screen and (max-width: 767px){
  .daGMts {
    grid-template-columns: repeat(3,70%);
  }
} */
@media(width<=600px){
 .contributers-content .single-grid {
    max-width: unset;
  }
  .contributers-background .contributers-content .contributer-header {
    font-size: 3rem;
  }
  .contributers-background .contributers-content {
    gap: 2.5rem;
  }
}
/* @media screen and (max-width: 600px){
  .cIFHrE{
      grid-template-columns: repeat(1,90%) !important;
  }
} */

.single-grid img{
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
