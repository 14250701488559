@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;1,700;1,800&display=swap');

*{
  padding : 0;
  margin:  0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

html{
  font-size: 62.5%;
}
body{
  height: 200vh;
}

.desktop-header{
  position: sticky;
  top: 0;
  z-index: 1000;
  left: 0;
  right: 0;
}

header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 8.5rem;
  padding: 0 9.5rem;
  background: #f7f7f7ff;
  border-bottom: 1px solid #d5d5d5;
  /* position: sticky;
  top: 0;
  z-index: 999; */
  @media(width>1600px){
    width: 1400px;
    margin: auto;
  }
}

header .navbar-logo img{
  max-width: 14.4rem;
  max-height: 4.5rem;
  cursor: pointer;
}

header .navbar-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.nav-link{
  font-family: "Montserrat", Sans-serif, serif;
  font-size: 15px;
  font-weight: 600;
  color: #7a7a7a;
}

.nav-link:hover{
  color: #0d3a63ff;
}

.navbar-list li .active{
  color: #0d3a63ff;
}

header .btn-donate{
  background: #0d3a63ff;
  padding: 1.1rem 3.5rem;
  font-size: 1.5rem;
  color : white;
  cursor: pointer;
  border-radius: 25px;
  border: 2px solid #0d3a63ff;
  font-weight: 500;
  font-family: "Montserrat", Sans-serif, serif;
}

header .btn-donate:hover{
  background: white;
  border: 2px solid #0d3a63ff;
  color: #0d3a63ff;
  font-size: 1.5rem;
  font-weight: 500;
  font-family: "Montserrat", Sans-serif, serif;
}

.sub-header{
  width: 100%;
  height: 5.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f7f7ff;
  border-bottom: 1px solid #d5d5d5;
  /* position: sticky;
  z-index: 999;
  left: 0; */
}

.sub-header .navbar-list{
  display: flex;
  gap: 3rem;
  align-items: center;
  justify-content: center;
}

.sub-header .nav-link{
  font-weight: 500;
  font-style: normal;
}

.nav-btns{
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-bars{
  display: none;
}

.navbar-mobile{
  display: none;
}

@media (width<1200px) {
  header .navbar-list{
    display: none;
  }
  .sub-header{
    display: none;
  }
  .btn-bars{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4.7rem;
  }
  .btn-donate{
    display: none;
  }
  .navbar-mobile{
    display: block;
  }
  .navbar-items-mobile li{
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    background: #fff;
    border-top: 1px solid #d5d5d5;
    padding-inline: 9.5rem;
    justify-content: space-between;
  }
  .navbar-items-mobile li:first-child{
    border-top: none;
  }
  .navbar-items-mobile li a{
    font-family: "Montserrat", Sans-serif, serif;
    font-weight: 600;
    font-size: 1rem;
    color: #7a7a7a;
  }
  .navbar-items-mobile li a:hover{
    color: #0d3a63ff;
  }
  .dropdown-icon{
    font-size: 1.4rem;
    color: #7a7a7a;
  }
  .dropdown li{
    padding-inline:10.5rem;
  }
}

@media (width<768px) {
  header{
    padding: 0 3rem;
    height: 6.3rem;
  }
  header .navbar-logo img{
    width: 12.2rem;
    height: 3.6rem;
  }
  .navbar-items-mobile li{
    padding-inline: 3rem;
  }
  .dropdown li{
    padding-inline: 4.5rem;
  }
}