@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
    color: #444444;
    font-size: 1.15vw;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    /* background: black; */
}

a {
    text-decoration: none;
}

img {
    max-width: 100%;
}

input, select, textarea, email, password {
    font-size: 1.1vw;
    font-family: 'Roboto', sans-serif;
}

.hide {
    display: none;
}

.container {
    display: block; /*width: 89.6vw;*/
    width: 1250px;
    margin: 0 auto;
}

.container::after {
    content: '';
    display: block;
    clear: both;
}


.headertop.fixed {
    position: fixed;
    top: 0;
    left: 0; /*background:rgba(0,108,145,1);*/
    width: 100%;
    z-index: 99;
    animation-name: menu_sticky;
    animation-duration: 0.60s;
    animation-timing-function: ease-out;
    -webkit-box-shadow: 0 13px 30px -12px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0 13px 30px -12px rgba(0, 0, 0, 0.75);
    box-shadow: 0 13px 30px -12px rgba(0, 0, 0, 0.75);

}

.headertop {
    position: relative;
    background: white;
}

.headertop.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    background: #fff;
}

.heighthead.fixed {
    height: 97px;
}

.headertop.fixed .logo {
    width: 108px;
}


.headertop .container {
    position: relative;
}

.logo {
    float: left;
    max-width: 200px;
    position: absolute;
    z-index: 99;
    padding: 2px;
    background: #fff;
    border-radius: 0 0 8px 8px;
}

.toplinks {
    float: right;
    margin-left: 35%;
    background: #f1f1f1;
    border-radius: 0 0 6px 6px;
    overflow: hidden;
}

.toplinks > ul {
    list-style: none;
}

.toplinks > ul > li {
    float: left;
    position: relative;
}

.toplinks > ul > li > a {
    color: #000;
    font-size: 15px;
    display: block;
    padding: 8px 15px;
}

.toplinks > ul > li {
    border-left: 1px solid #ddd;
}

.toplinks > ul > li:first-child {
    border: none;
}

.toplinks > ul > li > a i {
    min-width: 14px;
    text-align: center;
}

.topmenu {
    float: right;
    margin-top: 15px;
}

.topmenu > ul {
    list-style: none;
}

.topmenu > ul > li {
    float: left;
    position: relative;
}

.topmenu > ul > li > a {
    color: #000;
    font-size: 16px;
    display: block;
    padding: 12px 20px;
    line-height: 24px;
    font-weight: 400;
}

.topmenu > ul > li.donatebtn a {
    padding: 9px 30px;
    border-radius: 30px;
    background: #f57324;
    color: #fff;
}




.donatebtn {
    margin-left: 60px;
}

.searchbg {
    float: right;
    position: absolute;
    right: 160px;
    top: 50px;
}

.searchicon {
    display: block;
    width: 44px;
    height: 42px;
    text-align: center;
    line-height: 42px;
    border-left: 1px solid #ddd;
    cursor: pointer;
}

.searchbox {
    display: none;
    width: 320px;
    top: 0;
    right: 44px;
    position: absolute;
}

.searchbg.active .searchbox {
    display: block;
}

.searchbox input {
    width: calc(100% - 50px);
    float: left;
    border: 1px solid #f57324;
    height: 44px;
    font-size: 16px;
    padding: 10px;
}

.searchbox .searchbtn {
    width: 50px;
    float: left;
    background: #f57324;
    color: #fff;
}

.searchicon.active .fa-search::before {
    content: "\f00d";
}

.navicon {
    display: none;
}

input:focus {
    outline: none;
    box-shadow: none;
}

.mobilelink {
    display: none;
}

.mobilelogo {
    text-align: center;
}

.mobilelogo img {
    max-height: 70px;
}

.droplog {
    cursor: pointer;
}

.logindrop {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 9;
    background: #f57324;
    width: 100px;
    list-style: none;
    padding: 0;
    margin: 0;
    display: none;
}

.logindrop:after {
    bottom: 100%;
    left: 25px;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(245, 115, 36, 0);
    border-bottom-color: #f57324;
    border-width: 6px;
    margin-left: -6px;
}

.logindrop li:first-child {
    border-bottom: 1px solid #fff;
}

.logindrop li a {
    display: block;
    padding: 7px 10px;
    color: #fff;
}

/* responsive code for navbar --------------> */

@media (max-width: 1279px) {
    .container {
        width: 100%;
        padding: 0 15px;
    }
}



@media only screen and (max-width: 991px) {
    .searchicon {
        display: none;
    }
    .toplinks{overflow: inherit;}
    input, select, textarea, email, password {font-size: 14px;}
    body {font-size: 14px;}
    header .logo {max-width: 110px; padding:2px 5px;    position: unset;}
    header .logo img {width: 100%;}

    .topmenu{margin-top: 0;}
    .mobilelink{display: block;}
    .desktoplink{display: none;}

    .headertop{min-height: 60px;}
    .donatebtn {margin-left: 0;}
    .toplinks{ margin:15px 30px 0px 0px; background:#fff; }
    .toplinks .donatebtn a{background:#f57324; color:#fff; border-radius: 25px;}
    .toplinks > ul > li {border-left:0;}


    .navicon{display: block; float: right; cursor: pointer; position: absolute; top:18px; right:20px; z-index: 99; width:22px; height: 30px; padding-top: 15px;}
    .navicon span{ width:20px; height:2px; background:#3c7800; position: relative; display: block;border-radius: 2px;}
    .navicon span:before, .navicon span:after{content:""; width:20px; height:2px; background:#3c7800; display:block; position:absolute; left: 0; border-radius: 2px;}
    .sticky .navicon {top: 7px;}
    .sticky .navicon span:before, .sticky .navicon span:after, .sticky .navicon span, header:hover .navicon span, header:hover .navicon span:before, header:hover .navicon span:after, body:not(.home) .navicon span, body:not(.home) .navicon span:before, body:not(.home) .navicon span:after{ background:#3c7800; }


    header .navicon span:after { width:100%;  }
    header .navicon span:before{top: -7px;   }
    header .navicon span:after{bottom: -7px; }
    header .navicon.active span:before, header .navicon.active span:after { width:20px; top: -7px; background:#2c2928; }
    header .navicon.active{position: fixed; top: 15px; right: 15px;}
    header .navicon.active span{ background: none;}
    header .navicon.active span:before{ transform: rotate(-48deg);}
    header .navicon.active span:after{ transform: rotate(45deg); bottom: 6px;}

    .desktopshow{display: none;}
    .mobilelink, .mobileshow{display: block;}

    .topmenu { box-shadow: 0 0 3px #ccc; position: fixed;top: 0;right: 0; z-index: 98; width: 100%; padding:10px 0px 30px; height: 100%; overflow-x: hidden;background: #fff /*url(../images/logo.png) center top no-repeat; background-size: 30px*/; overflow: auto; -webkit-font-smoothing: antialiased;  transform-origin: 0% 0%;  transform: translate(100%, 0);  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);}
    .topmenu.showmenu{ transform: translate(0%, 0); }
    header .topmenu.showmenu > ul > li > a{color:#2c2928;}
    .topmenu > ul > li{width: 100%; position: relative; border-top: 1px solid #ddd;}
    .topmenu > ul > li:hover > a{border: none!important;}
    .topmenu > ul > li > a {padding:13px 15px;font-size: 15px;line-height: 24px; border: none!important}
    .topmenu > ul > li > ul {width: 100%;  background:#f1f1f1; position: static;  padding: 0; border: none;opacity: 1; visibility: visible; display: none;}
    .topmenu > ul > li > ul > li{width: 100%; min-height:auto; padding: 0; border-top: 1px solid #ddd;}
    .topmenu > ul > li > ul > li:first-child, .topmenu > ul > li:first-child{border: none;}
    .topmenu > ul > li > ul > li a{font-size: 13px; padding:15px 25px;}
    .topmenu > ul > li > ul > li > ul{display: none; background:#ebebeb; padding: 0;}
    .topmenu > ul > li > ul > li > ul > li > a{padding:13px 30px;}
    .menutitle {font-size: 14px; margin-top: 10px;}

    .email input[type=text], .loginW .password input[type=password] {padding-left:38px; background-size: 20px auto;}

}



@media only screen and (max-width: 767px) {

    .headertop{ border-bottom:1px solid #f1f1f1; }


    .linkBtn a { font-size: 16px; padding: 10px 25px;}

    .toplinks {
        margin: 10px 30px 0 0;
    }
    .navicon{
        top:10px;
    }

}


@media only screen and (max-width: 579px) {

    header nav .links .mainLnks ul li {display: block; text-align: left; border-top: 1px solid #f9f9f9;}
    header nav .links .mainLnks ul li a {display: block; line-height: 32px;}
    header nav .links .mainLnks {padding: 0;}
    header nav .search form {width: 100%;}

}





@media only screen and (max-width: 479px) {
    .loginW .container {width:320px; padding: 20px;}
    header .logo {
        max-width: 85px;
        padding: 0;
    }
}